import React, { useState } from "react";
import { useModel, useWidget, Widget, core, $Array, useValue } from "@essenza/react";
import { RoomModel, } from "../../data/room";
import { $CANVAS, IT } from "../../core/utils";
import { Checkbox, DatePicker, Popconfirm, Select, notification } from "antd";
import { FiDownload } from "react-icons/fi";
import { BsPrinter } from "react-icons/bs";
import dayjs from 'dayjs';
import { Menu } from "../../component/menu";
import { Loader } from "../../component/loader";
import { IoEyeOutline } from "react-icons/io5";
import { Transactions } from "../../core/transaction";
import { Transactioncard } from "../transaction/transactioncard";
import html2canvas from 'html2canvas';
import { ReportVM } from "../../viewmodel/report";
import { EinvDetail } from "../einvoice/report";
import { RiMailSendLine } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { SendReport } from "../property/report";
import { VscFeedback } from "react-icons/vsc";
import { Suggestioncard, SuggestioncardVM } from "../suggestion/suggestioncard";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { Rating } from "../property/rating";
import { BsStars } from "react-icons/bs";

export function RoomReport({ source }) {
    const vm = useWidget(RoomReportVM);
    const properties = useValue("properties")
    const [room, data] = useModel(RoomModel);

    vm.setProperties(properties);
    vm.references = [];
    room.request(m => {
        m.report(vm.interval);
    }, [vm.interval]);

    const message = () => notification.info({ message: "Disponibile a breve.", duration: 2 });

    //if (!data) return "nessun dato da visualizzare";
    return (<Widget>
        <div className="flex gap-2 items-center">
            <div className="flex gap-2 p-2 bg-white rounded-2xl font-bold">
                <DatePicker onChange={date => vm.onInterval(date.toDate(), true)} className="h-8 bg-white" format="DD-MM-YYYY" value={dayjs(vm.interval.start)} />
                <DatePicker onChange={date => vm.onInterval(date.toDate())} className="h-8 bg-white" format="DD-MM-YYYY" value={dayjs(vm.interval.end)} />
                <Select defaultValue={-3} popupMatchSelectWidth={false} className=" bg-white max-w-24 h-8" fieldNames={{ label: "dealname", value: "id" }}
                    optionFilterProp="dealname" onSelect={value => vm.onFilter(value)}
                    options={vm.list} />
            </div>
            <Menu source={vm.filteritem} unselected={vm.unselected} lite itemClick={(_, item) => vm.onMenuItem(item.value)} />
            <div className="flex-auto">
                <DatePicker placeholder="Mese" onChange={date => vm.onMonth(date)} className="h-8 bg-white w-20" picker="month" />
            </div>
            <FiDownload className="text-2xl cursor-pointer" onClick={()=>vm.emit("DOWNLOAD_ALL")} /> 
            <BsPrinter className="text-2xl cursor-pointer" onClick={message} />
        </div>
        <div className="w-full h-[calc(100vh-132px)] mt-3 flex">
            {
                room.pending ? <Loader className="mt-12" /> :
                    <div className="w-full overflow-auto flex flex-col  gap-4 rounded-2xl">
                        {
                            data ? data.map((apt, i) => {
                                if (apt.idproperty === null || (vm.selected > -3 && apt.idproperty !== vm.selected)) return null;
                                /*apt.totalcost = 0;
                                if (apt.costs) {
                                    apt.totalcost = 0;
                                    apt.costs.forEach(cost => {
                                        if (cost && cost.enabled)
                                            apt.totalcost += cost.amount + cost.vat_amount;
                                    });
                                }*/
                                const ref = { current: null };
                                vm.references.push({ref: ref, id: apt.idproperty});
                                const onclick = () => {
                                    html2canvas(ref.current).then(function (canvas) {
                                        //$CANVAS.download(canvas, apt.dealname);
                                        room.send(canvas);
                                    });
                                }
                                apt.grouping = new Transactions();
                                apt.grouping.import(apt.costs);

                                return (<div className={"bg-white w-full rounded-2xl p-6 " + (i !== 0 && vm.selected === 0 ? "mt-4" : "")}>
                                    <div className="flex gap-2 items-center">
                                        <h1 className="flex-auto font-bold text-lg " >{apt.dealname}</h1>
                                        {
                                            vm.authorized && vm.interval.isMonthly && apt.idproperty > 0 &&
                                            <h1 className="flex items-center gap-1">Report {vm.interval.toMonthlyLabel()}
                                                {
                                                    vm.interval.monthlyBeforeOf(apt.report) ? <FaCircle className="text-green-600" /> :
                                                        <div className="flex gap-2 items-center">
                                                            <FaCircle className="text-red-600" />
                                                            <button className="btn-ghost rounded-full" onClick={() => vm.emit("APPROVE", apt)}>Approva</button>
                                                        </div>
                                                }

                                            </h1>
                                        }
                                        {apt.idproperty > 0 && <>
                                            <button onClick={() => vm.emit("EINV_DETAIL", apt)}>Dettaglio</button>
                                            <BsStars className="text-2xl cursor-pointer" onClick={() => vm.emit("RATING", apt)} />
                                            <VscFeedback className="text-2xl cursor-pointer" onClick={() => vm.emit("SUGGESTION", apt)} />
                                        </>}
                                        <RiMailSendLine className="text-2xl cursor-pointer" onClick={() => vm.emit("SEND_EMAIL", { element: ref.current, apt: apt })}></RiMailSendLine>
                                        <FiDownload className="text-2xl cursor-pointer" onClick={() => vm.emit("DOWNLOAD", { element: ref.current, name: apt.dealname })}></FiDownload>
                                    </div>

                                    <ReportView vm={vm} apt={apt} pointer={ref} />
                                    {apt.rooms && apt.rooms.length > 2 && apt.rooms.map(room => <ReportRoomView apt={room} />)}
                                </div>)
                            }) : <h1 className="ml-2 text-lg font-semibold">Nessun dato da visualizzare</h1>
                        }
                    </div>
            }
        </div>
    </Widget>)
}

function RoomReportVM() {
    ReportVM.call(this);
    this.authorized = this.context.role.authorize("ADMIN,USER");
    this.references = [];
}

core.prototypeOf(ReportVM, RoomReportVM, {
    setProperties(list) {
        if (list)
            this.list = [{ id: -3, dealname: "Tutti" }, { id: -2, dealname: "Complessivo" }, { id: -1, dealname: "Sublocazione" }, { id: 0, dealname: "In Gestione" }, ...list]
    },
    intent: {
        COST_DETAIL({ data }) {
            /*if(!this.authorized) {
                notification.error({ title: "Attenzione", message: "Azione non consentita, accesso ristretto per l'utente" });
                return;
            }*/
            this.context.openModal({
                kind: "info",
                content: <ReportCostView source={data.value} category={data.category} apt={data.apt} />,
                title: "Costi",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                onOk: () => {
                    this.update();
                },
            })
        },

        EINV_DETAIL({ data }) {
            const info = this.interval.toISODate()
            info.id = data.idproperty;
            info.name = data.roomname;
            this.context.openModal({
                kind: "info",
                content: <EinvDetail info={info} />,
                title: "Dettaglio Prenotazioni",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 1080,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                /*onOk: () => {
                    this.update();
                },*/
            })
        },

        RATING({ data }) {
            const info = this.interval.toISODate()
            info.id = data.idproperty;
            info.name = data.roomname;
            this.context.openModal({
                kind: "info",
                content: <Rating info={info} />,
                title: data.dealname,
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 510,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                /*onOk: () => {
                    this.update();
                },*/
            })
        },

        COST_UPSERT({ token }) {
            return this.transactionvm.emit("SAVE").then(r => token.data = r.data);;
        },

        DOWNLOAD({ data }) {
            let el = data.element;
            const w = el.style.width;
            el.style.width = "1028px";
            html2canvas(el).then(function (canvas) {
                el.style.width = w;
                $CANVAS.download(canvas, data.name);
            });
        },

         DOWNLOAD_ALL: async function({ data }) {
            if(this.references.length === 0){
                notification.info({message: "Nessun report da scaricare"});
                return;
            }

            let el, w, canvas;
            const source = [];

            for (const data of this.references) {
                el = data.ref.current;
                console.log("EL: ", el);
                w = el.style.width;
                el.style.width = "1028px";
                canvas = await html2canvas(el);
                console.log(canvas);
                source.push({data: canvas.toDataURL("image/png").replace('data:image/png;base64,', ''), id: data.id});
                el.style.width = w;

            }

            /*this.references.forEach(await (async data => {
                el = data.ref.current;
                console.log("EL: ", el);
                w = el.style.width;
                el.style.width = "1028px";
                canvas = await html2canvas(el);
                console.log(canvas);
                source.push({ data: canvas.toDataURL("image/png").replace('data:image/png;base64,', ''), id: data.id})
                el.style.width = w;
            }));*/

            this.$room.downloadAll({images: source, period: this.interval.toMonthlyLabel()}).then(r=>{
                window.open(r.data + ".zip", '_blank');
            });
        },

        APPROVE({ data }) {

            if (this.interval.isMonthly) {
                const date = new Date(this.interval.end.getTime());
                date.setDate(32);
                this.$room.approve(date, data.idproperty).then(() => {
                    notification.info({ message: "Report approvato con successo", duration: 2 });
                    data.report = date;
                    this.update();
                });
            }

        },

        SEND_EMAIL({ data }) {
            html2canvas(data.element).then((canvas) => {
                const ref = { current: null };
                this.context.openModal({
                    kind: "confirm",
                    content: <SendReport parent={ref} apt={data.apt} interval={this.interval} />,
                    title: "Invio Report",
                    okText: "INVIA",
                    cancelText: "ANNULLA",
                    centered: true,
                    width: 980,
                    icon: null,
                    cancelButtonProps: { className: "btn-ghost rounded-full" },
                    okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                    onOk: (close) => {
                        ref.current.validate().then(r => {
                            console.log(r)
                            if (r) {
                                close();
                                this.$room.send(canvas, ref.current.state).then(() => {
                                    notification.info({ message: "Report inviato con successo", duration: 2 });
                                    this.interval.isMonthly && this.update();
                                });
                            }
                        });
                    },
                });
            });
        },

        SUGGESTION({ data }) {
            
            const modal = this.context.openModal({
                kind: "info",
                content: <Suggestioncard source={data} date={this.interval.toISODate().start} />,
                title: "Suggerimenti",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                onOk: () => {
                    
                },
            });

            this.bind(SuggestioncardVM).listen("SAVE", r=>{
                console.log("INSERT SUGGESTION",r);
                modal.destroy();
                this.unbind(SuggestioncardVM);
            });
        },

        TRANSACTION_DELETE({ data }) {
            console.log(data);
            const obj = this.context.newInstance("transaction", { id: data.id });
            obj.delete();
        },
    }
});

function ReportView({ apt, vm, pointer }) {
    return (<>
        {/* <h1 className="font-bold text-lg mt-4" >{apt.dealname}</h1> */}
        <div ref={pointer} className="bg-white w-full rounded-2xl p-6 mt-4">
            <div className="flex">
                <div className="flex-1">
                    <div className="flex flex-col gap-3 mt-3 font-bold">
                        <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl items-center">
                            <div className="flex-1">{apt.kind ? apt.kind : "Complessivo"}</div>
                            <div className="flex-1">Importo</div>
                            <div className="flex-1">Iva</div>
                            <div className="flex-1">Descrizione</div>
                        </div>
                        {
                            apt.idproperty === -1 || (apt.rooms && apt.rooms[0].invoicing === 'O')
                                ? <RentalApartment apt={apt} vm={vm} />
                                : <ManagedApartment apt={apt} vm={vm} />
                        }
                    </div>
                </div>
                <div className="pl-6 pt-3">
                    <div className="bg-gray-100 w-36 h-28 p-8 rounded-xl">
                        <h1>ADR</h1>
                        <h1 className="text-2xl font-bold">{IT.currency(apt.adr)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-28 p-8 rounded-xl mt-3">
                        <h1>%OCC</h1>
                        <h1 className="text-2xl font-bold">{IT.percent(apt.perc)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-28 p-8 rounded-xl mt-3">
                        <h1>REVPAR</h1>
                        <h1 className="text-2xl font-bold">{IT.currency(apt.revpar)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-28 p-8 rounded-xl mt-3">
                        <h1>AVG STAY</h1>
                        <h1 className="text-2xl font-bold">{IT.decimal(apt.mstay)}</h1>
                    </div>
                </div>
            </div>

        </div>
    </>)
}

export function ReportCostView({ source, category, apt }) {
    const vm = useWidget(RoomReportVM);
    const [editable, setEditable] = useState(false);
    const format = (t, del) => t ? (del ? (del + t) : t) : "";



    const onchange = (item, value) => {
        const obj = vm.context.newInstance("transaction", { id: item.id, enabled: item.enabled });
        item.enabled = value;
        obj.$enabled = value;
        obj.save();
    }

    const onselect = (item, value) => {
        const obj = vm.context.newInstance("transaction", { id: item.id, category: item.category });
        item.category = value;
        obj.$category = value;
        obj.save();
    }

    const onremove = (item) => {
        console.log(item);
        const obj = vm.context.newInstance("transaction", { id: item.id });
        obj.delete().then(()=>{
            $Array.removeItem(source, item);
            vm.update();
        });
    }

    const oncost = () => {
        vm.emit("COST_UPSERT").then(result => {
            if (result.data) {
                apt.costs.push(editable);
                apt.costs !== source && source.push(editable);
                setEditable(false)
            }
        })
    }

    const categoryList = [
        { label: "AFFITTO", value: "office_rental" },
        { label: "ALTRE SPESE", value: "cost" },
        { label: "COMMISSIONE", value: "commission" },
        //{ label: "CORRISPETTIVO", value: "cost" },
        { label: "LAVANDERIA", value: "linen" },
        { label: "PULIZIA", value: "cleaning" },
        { label: "UTENZA", value: "utility" },
        { label: "VOCE IN FATTURA", value: "item" },
    ];
    //if (!source) return <h1>Nessun voce presente</h1>

    return (<Widget><div className="flex flex-col gap-3 mt-3 font-bold">
        {editable &&  <>
            <div className="bg-gray-600 text-white py-2 px-4 rounded-xl">Nuova voce</div>
            <Transactioncard source={editable} parent={vm} />
            <div className="flex gap-3">
                <button className="btn-dark bg-black rounded-xl h-10" onClick={() => setEditable(false)}>Annulla</button>
                <button className="btn-lite bg-sec rounded-xl" onClick={oncost}>Conferma</button>
            </div>
        </>}
        <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl">
            <div className="basis-1/5">Importo</div>
            <div className="basis-1/5">Iva</div>
            <div className="basis-2/5">Descrizione</div>
            <div className="basis-1/5">Categoria</div>
        </div>
        {source ? source.map(cost => {
            return cost
                ? <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                    <div className="basis-1/5 flex gap-2 items-center"><Checkbox disabled={!vm.authorized} defaultChecked={cost.enabled} onChange={e => onchange(cost, e.target.checked)} />{IT.currency(cost.amount)}</div>
                    <div className="basis-1/5">{IT.currency(cost.vat_amount)}</div>
                    <div className="basis-2/5">{format(cost.label) + format(cost.reference, " - ") + " " + format(cost.note, " - ")}</div>
                    <div className="basis-1/5"><Select disabled={!vm.authorized} onSelect={v => onselect(cost, v)} defaultValue={cost.category} options={categoryList} variant="filled" /></div>
                    {cost.operation_type === 'manual' && vm.authorized && <Popconfirm
                        title="Elimina Transazione"
                        description="Confermi di voler eliminare la transazione?"
                        onConfirm={() => onremove(cost)}
                        okText="Si"
                        cancelText="No"
                    >
                        <MdOutlineDeleteSweep className="text-4xl cursor-pointer" />
                    </Popconfirm>}
                </div>
                : null
        }) : <h1>Nessun voce presente</h1>}

        {!editable && vm.authorized && <div><button className="btn-lite bg-sec rounded-xl" onClick={() => setEditable(vm.context.newInstance("transaction", { enabled: true, side: 'debit', operation_type: 'manual', category, idproperty: apt.idproperty }))}>Crea nuova voce</button></div>}
    </div>
    </Widget >
    )
}

function ReportRoomView({ apt }) {
    let commission = apt.icommission === 2 ? (apt.commission - apt.cleaning - apt.linen) : apt.commission;
    return (<>
        {/* <h1 className="font-bold text-lg mt-4" >{apt.dealname}</h1> */}

        <div className="bg-white w-full rounded-2xl p-6 mt-4">
            <div className="flex">
                <div className="flex-1">
                    <div className="flex flex-col gap-3 mt-3 font-bold">
                        <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl">
                            <div className="flex-1">{apt.kind ? apt.kind : "Complessivo"}</div>
                            <div className="flex-1">Importo</div>
                            <div className="flex-1">Iva</div>
                            <div className="flex-1">Descrizione</div>
                        </div>
                        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">Revenue lorde</div>
                            <div className="basis-1/4">{IT.currency(apt.total)}</div>
                        </div>
                        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">Revenue al netto delle OTA</div>
                            <div className="basis-1/4">{IT.currency(apt.revenue)}</div>
                        </div>
                        {apt.invoicing !== 'O' && <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">Provvigione</div>
                            <div className="basis-1/4">{IT.currency(commission)}</div>
                            <div className="basis-1/4">{IT.currency((commission * 22) / 100)}</div>
                        </div>}
                        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">Pulizie</div>
                            <div className="basis-1/4">{IT.currency(apt.cleaning)}</div>
                        </div>
                        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">Lavanderia</div>
                            <div className="basis-1/4">{IT.currency(apt.linen)}</div>
                        </div>
                        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">{apt.invoicing === 'O' ? "Margine Operativo" : "Utile"}</div>
                            <div className="basis-1/4">{IT.currency(apt.revenue - apt.commission - apt.commissionvat - apt.cleaning - apt.linen)}</div>
                        </div>
                        {/* {apt.invoicng !== 'O' && <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                            <div className="basis-1/4">F24</div>
                            <div className="basis-1/4">{IT.currency(apt.f24)}</div>
                        </div>} */}
                    </div>
                </div>
                <div className="pl-6 pt-3">
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>ADR</h1>
                        <h1 className="text-2xl font-bold">{IT.currency(apt.adr)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl mt-3">
                        <h1>%OCC</h1>
                        <h1 className="text-2xl font-bold">{IT.percent(apt.perc / 100)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl mt-3">
                        <h1>REVPAR</h1>
                        <h1 className="text-2xl font-bold">{IT.currency(apt.revpar)}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl mt-3">
                        <h1>AVG STAY</h1>
                        <h1 className="text-2xl font-bold">{IT.decimal(apt.mstay)}</h1>
                    </div>
                </div>
            </div>

        </div>
    </>)
}

function RentalApartment({ apt, vm }) {
    const grouping = apt.grouping;
    apt.totalcost = grouping.cost.amount;
    const isApartment = apt.idproperty > 0;
    return (
        <>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Revenue lorde</div>
                <div className="basis-1/4">{IT.currency(apt.total)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Revenue al netto delle OTA</div>
                <div className="basis-1/4">{IT.currency(apt.revenue)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Pulizie
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.cleaning.items, category: "cleaning", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(apt.cleaning + grouping.cleaning.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Lavanderia
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.linen.items, category: "linen", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(apt.linen + grouping.linen.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Utenze
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.utility.items, category: "utility", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(grouping.utility.amount)}</div>
                <div className="basis-1/4">{IT.currency(grouping.utility.vat_amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Affitto
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.office_rental.items, category: "office_rental", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(grouping.office_rental.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Altre spese
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.cost.items, category: "cost", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(grouping.cost.net)}</div>
                <div className="basis-1/4">{IT.currency(grouping.cost.vat_amount)}</div>
            </div>
            <div className="flex bg-[#FAC710] w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Margine Operativo</div>
                <div className="basis-1/4">{IT.currency(apt.revenue - apt.cleaning - apt.linen - apt.totalcost - grouping.office_rental.amount - grouping.utility.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Margine di contribuzione</div>
                <div className="basis-1/4">{IT.currency(apt.revenue - apt.linen - grouping.linen.amount - apt.cleaning - grouping.cleaning.amount)}</div>
            </div>
        </>
    )
}

function ManagedApartment({ apt, vm }) {
    const grouping = apt.grouping;
    apt.totalcost = grouping.cost.amount;
    const isApartment = apt.idproperty > 0;
    let commission = apt.commission; //apt.icommission === 2 ? (apt.commission - (apt.cleaning + grouping.cleaning.amount) - (apt.linen + grouping.linen.amount)) : apt.commission
    commission += grouping.commission.amount; //+ grouping.commission.vat_amount
    return (
        <>
            <div className="flex items-center bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Revenue lorde</div>
                <div className="basis-1/4">{IT.currency(apt.total)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Revenue al netto delle OTA</div>
                <div className="basis-1/4">{IT.currency(apt.revenue)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Provvigione
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.commission.items, category: "commission", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(commission)}</div>
                <div className="basis-1/4">{IT.currency((commission * 22) / 100)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Pulizie
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.cleaning.items, category: "cleaning", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(apt.cleaning + grouping.cleaning.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Lavanderia
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.linen.items, category: "linen", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(apt.linen + grouping.linen.amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4 flex items-center gap-2">Altre spese
                    {isApartment && <IoEyeOutline onClick={() => vm.emit("COST_DETAIL", { value: grouping.extra, category: "cost", apt: apt })} className="text-xl cursor-pointer" />}
                </div>
                <div className="basis-1/4">{IT.currency(grouping.cost.amount + grouping.utility.amount)}</div>
                <div className="basis-1/4">{IT.currency(grouping.cost.vat_amount + grouping.utility.vat_amount)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Utile</div>
                <div className="basis-1/4">{IT.currency(apt.revenue - (commission + apt.comvat + apt.cleaning + grouping.cleaning.amount + apt.linen + grouping.linen.amount + apt.totalcost + grouping.utility.amount))}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">F24</div>
                <div className="basis-1/4">{IT.currency(apt.f24)}</div>
            </div>
            <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl">
                <div className="basis-1/4">Netto proprietario</div>
                <div className="basis-1/4">{IT.currency(apt.revenue - (commission + apt.comvat + apt.cleaning + grouping.cleaning.amount + apt.linen + grouping.linen.amount + apt.totalcost + grouping.utility.amount + apt.f24))}</div>
            </div>
        </>
    )
}



/*const onclick = async function (e) {
    e.preventDefault();
 
        numberPhone.setCustomValidity("");
        
        // Faccio la chiamata API
        let result = await validaAPI(numberPhone.value);	
        console.log("Arrivooo",result);
        
        // Se il numero NON ESISTE
            if (result.valid === false) {
                console.log("IF");
                numberPhone.setCustomValidity("Il numero di telefono inserito non è un numero esistente!");
                numberPhone.reportValidity();
            }
        // Se il numero ESISTE
            else {
                console.log("ELSE");
                if (formNumVerify.checkValidity()) {
                    buttonNumVerify.removeEventListener ('click', onclick); 
                    buttonNumVerify.dispatchEvent(new Event('click', {'bubbles'    : true,'cancelable' : true}));
                    formNumVerify.submit();
                    formNumVerify.dispatchEvent(new Event('submit', {'bubbles'    : true,'cancelable' : true}));
                }
                else {
                    formNumVerify.reportValidity();
                }
            }

    	
        }

buttonNumVerify.addEventListener ('click', onclick); */